import { Route, Routes, Navigate } from 'react-router-dom';

import { getToken } from './helpers';

import Nav from './components/nav';
import Footer from './components/footer';
import ReportProblem from './components/reportProblem';

import Home from './pages/home';
import NotFound from './pages/notFound';
import PurchaseCourse from './pages/purchaseCourse';
import Login from './pages/login';
import Signup from './pages/signup';

import Dashboard from './pages/dashboard';
import Course from './pages/course';
import Lesson from './pages/lesson';
import LessonPage from './pages/lessonPage';
import Account from './pages/account';

export default function Main() {
	
	const appearance = localStorage.getItem('appearance');
	
	if(appearance === 'light') {
		document.body.classList.add('light');
	} else if(appearance === 'dark') {
		document.body.classList.add('dark');
	} else {
		document.body.classList.remove('dark');
		document.body.classList.remove('light');
	}
	
	return(
		<>
			<Nav />
			<div className="body">
				<Routes>
					<Route path="404" element={<NotFound />} />
					<Route path="/" element={<Home />} />
					<Route path="/purchase" element={<PurchaseCourse />} />
					<Route path="/dashboard" element={getToken() ? <Dashboard /> : <Navigate to="/login" />} />
					<Route path="/login" element={<Login />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/account" element={getToken() ? <Account /> : <Navigate to="/login" />} />
					<Route path="/:courseSlug/:lessonSlug/:pageSlug" element={<LessonPage />} />
					<Route path="/:courseSlug/:lessonSlug" element={<Lesson />} />
					<Route path="/:courseSlug" element={<Course />} />
					<Route path="*" element={<Navigate to="/404" replace />} />
				</Routes>		
			</div>	
			<ReportProblem />
			<Footer />
		</>
	)
}