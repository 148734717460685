import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { stringify, parseObject } from 'qs';

import { getToken } from '../helpers';

import { useAuthContext } from '../contexts';

import { Button, SpecialCharsButton, ToggleButtons } from '../components/atoms';
import { RichTextBlockRenderer } from '../components/renderers';
import { RenderGapFill, Mark, Keyboard } from '../components/exerciseComponents';

function extractAnswer(fragment) {
	var answer = null;
	var splitArray = fragment.match(/(.+)(\([^)]+\))(\[[^)]+\])(.+)/);
	
	splitArray.map((thisFragment, i) => {
		var findAnswer = thisFragment.match(/\[([^)]+)\]/);
		if(findAnswer) {
			answer = findAnswer[1];
		}
	});
	return answer;
}

export default function Exercise({
		content,
		nextPageDisabled,
		setNextPageDisabled
	}) {
		
	const authToken = getToken();
	const user = useAuthContext();
		
	const exerciseContent = content.exercise.data.attributes.content[0].rows;
	const questionCount = exerciseContent.length;
	
	var questionContent = [];
	
	exerciseContent.map((thisQuestion, i) => {
		questionContent.push({
			answer: extractAnswer(thisQuestion.content),
			content: thisQuestion.content,
			number: i+1,
			status: 'unfilled',
			userAnswer: ''
		});
	});	
	
	const [questions, setQuestions] = useState(questionContent);
	const [answersID, setAnswersID] = useState(null);
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const [exercise, setExercise] = useState({
		questionCount: questionCount,
		rightAnswers: 0,
		unfilledAnswers: questionCount
	});	
	
	var q = stringify({
		filters: {
			user: {
				$eq: user.id,
			},
			exercise: {
				$eq: content.id
			}
		}
	});
	
	useEffect(() => {	
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers?${q}`, {
			headers: {
				'Authorization': `Bearer ${authToken}`
			}
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			if(data.data.length>0) {
				setAnswersID(data.data[0].id);
				setQuestions(data.data[0].attributes.answerJSON);
				setLoading(false);
			} else {
				console.log("No answers, creating blank entry…");
				const newAnswerData = {
					data: {
						exercise: content.exercise.data.id,
						user: user.user.id,
						possibleMarks: exercise.questionCount,
						unfilledAnswers: exercise.unfilledAnswers,
						correctAnswers: exercise.rightAnswers,
						answerJSON: questions
					}
				}
				fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers`, {
					method: 'POST',
					headers: {
						'Authorization': `Bearer ${authToken}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(newAnswerData)
				})
				.then((res) => {
					return res.json();
				})
				.then((data) => {			
					setAnswersID(data.data.id);
					setLoading(false);
				})
				.catch((error) => {
					setError(true);
					setLoading(false);
					console.log(error);
				});
			}
		})
		.catch((error) => {
			setError(true);		
			setLoading(false);
			console.log(error);
		});
	}, [answersID]);
	
	useEffect(() => {
		var rightAnswers = 0;
		var unfilledAnswers = 0;
		var focusField = null;
		questions.map((thisQuestion, i) => {
			if(thisQuestion.status === "correct") {
				rightAnswers = rightAnswers+1;
			} else if(thisQuestion.status === "unfilled") {
				if(!focusField) {
					focusField = thisQuestion.number;
				}
				unfilledAnswers = unfilledAnswers+1;
			}
		});
		setExercise({
			...exercise,
			rightAnswers: rightAnswers,
			unfilledAnswers: unfilledAnswers,
			focusField: focusField
		});
	}, [questions]);
	
	useEffect(() => {
		if(exercise.unfilledAnswers > 0 || exercise.rightAnswers < exercise.questionCount) {
			setNextPageDisabled(true);
		} else {
			setNextPageDisabled(false);
		}		
	}, [exercise]);
	
	function handleShowKeyboard() {
		document.getElementById('keyboard').classList.toggle('visible');
	}
	
	const [saving, setSaving] = useState(false);
	const [saveError, setSaveError] = useState(false);
	
	function handleSaveWork() {
		setSaving(true);
		const answerData = {
			data: {
				answerJSON: questions
			}
		}
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/exercise-answers/${answersID}`, {
			method: 'PUT',
			headers: {
				'Authorization': `Bearer ${authToken}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(answerData)
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			setSaving(false);
		})
		.catch((error) => {
			setSaveError(true);
			setSaving(false);
			console.log(error);
		});
	}
	
	const [dropdownVisible, setDropdownVisible] = useState(false);
	
	function handleDropdownToggle(menu) {
		if(dropdownVisible) {			
			setDropdownVisible(false);
		} else {			
			setDropdownVisible(menu);
		}		
	}
	
	function handleLiveMarkingToggle() {
		handleDropdownToggle();
	}
	
	function handleRevealAnswers() {
		handleDropdownToggle();
	}
	
	function handleClearAnswers() {
		handleDropdownToggle();
		setQuestions(questions.map((thisQuestion, i) => {
			return {
				...thisQuestion,
				userAnswer: "",
				status: "unfilled"
			}
		}));
		setExercise({
			...exercise,
			rightAnswers: 0,
			unfilledAnswers: exercise.questionCount
		});
	}
	
	const toggleButtonLiveMarking = [{
			title: 'On',
			value: true
		},
		{
			title: 'Off',
			value: false
		}
	];
	
	const ref = useRef(null);
	
	useEffect(() => {
		if(dropdownVisible) {			
			document.body.classList.add('no-scroll');
		} else {			
			document.body.classList.remove('no-scroll');
		}				
	}, [dropdownVisible]);
	
	if(questions) {
		return(
			<>
				<div className="prose">
					<h1>{content.title}</h1>
				</div>			
				<div className="exercise-wrapper">
					<div className="block exercise">
						<div className="intro">
							<div className="instructions">
								<RichTextBlockRenderer content={content.exercise.data.attributes.instructions} />
							</div>				
							{!loading && (
								<div className="toolbar-buttons">
									<SpecialCharsButton onClick={handleShowKeyboard} />
{/* 									<Button
										icon="options"
										role={`toolbar${dropdownVisible ? ( " active" ) : ( "" ) }`}
										onClick={e => handleDropdownToggle("exercise")}
									/> */}
									<div className={`dropdown exercise-dropdown${dropdownVisible === 'exercise' ? (' visible') : ('')}`}>
										<ul>
											<li className="padded">
												<div className="icon">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M10.6875 11.7188C10.3125 12.125 9.65625 12.125 9.28125 11.7188L6.78125 9.21875C6.375 8.84375 6.375 8.1875 6.78125 7.8125C7.15625 7.40625 7.8125 7.40625 8.1875 7.8125L9.96875 9.59375L14.2812 5.3125C14.6562 4.90625 15.3125 4.90625 15.6875 5.3125C16.0938 5.6875 16.0938 6.34375 15.6875 6.71875L10.6875 11.7188ZM10.6875 18.7188C10.3125 19.125 9.65625 19.125 9.28125 18.7188L5.28125 14.7188C4.875 14.3438 4.875 13.6875 5.28125 13.3125C5.65625 12.9062 6.3125 12.9062 6.6875 13.3125L9.96875 16.5938L17.2812 9.3125C17.6562 8.90625 18.3125 8.90625 18.6875 9.3125C19.0938 9.6875 19.0938 10.3438 18.6875 10.7188L10.6875 18.7188Z" fill="#0B00D1"/>
													</svg>																															
												</div>
												<div className="dropdown-options-wrapper">													
													Live marking
													<ToggleButtons
														items={toggleButtonLiveMarking}
														value={true}
														onClick={handleLiveMarkingToggle}
													/>
												</div>
											</li>
										</ul>
										<ul>
											<li className="linkless" onClick={handleRevealAnswers}>
												<div className="icon">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M12 4C16.4062 4 20 7.59375 20 12C20 16.4375 16.4062 20 12 20C7.5625 20 4 16.4375 4 12C4 7.59375 7.5625 4 12 4ZM12 18.5C15.5625 18.5 18.5 15.5938 18.5 12C18.5 8.4375 15.5625 5.5 12 5.5C8.40625 5.5 5.5 8.4375 5.5 12C5.5 15.5938 8.40625 18.5 12 18.5ZM12 14.5C12.5312 14.5 13 14.9375 13 15.5C13 16.0625 12.5312 16.5 12 16.5C11.4062 16.5 11 16.0625 11 15.5C11 14.9375 11.4375 14.5 12 14.5ZM13.0312 8C14.2812 8 15.25 8.96875 15.2188 10.1875C15.2188 10.9375 14.8125 11.6562 14.1562 12.0625L12.75 12.9375V13C12.75 13.4062 12.4062 13.75 12 13.75C11.5938 13.75 11.25 13.4062 11.25 13V12.5C11.25 12.25 11.375 12 11.625 11.8438L13.4062 10.7812C13.625 10.6562 13.75 10.4375 13.75 10.1875C13.75 9.8125 13.4062 9.5 13 9.5H11.4062C11.0312 9.5 10.75 9.8125 10.75 10.1875C10.75 10.5938 10.4062 10.9375 10 10.9375C9.59375 10.9375 9.25 10.5938 9.25 10.1875C9.25 8.96875 10.2188 8 11.4375 8H13.0312Z" fill="#0B00D1"/>
													</svg>																			
												</div>
												Reveal answers
											</li>
											<li className="linkless danger" onClick={handleClearAnswers}>
												<div className="icon">
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M9.21875 4.5625C9.375 4.21875 9.71875 4 10.0938 4H13.875C14.25 4 14.5938 4.21875 14.75 4.5625L15 5H18C18.5312 5 19 5.46875 19 6C19 6.5625 18.5312 7 18 7H6C5.4375 7 5 6.5625 5 6C5 5.46875 5.4375 5 6 5H9L9.21875 4.5625ZM17.3125 18.5938C17.2812 19.4062 16.625 20 15.8125 20H8.15625C7.34375 20 6.6875 19.4062 6.65625 18.5938L5.96875 8H18L17.3125 18.5938Z" fill="#EC1801"/>
													</svg>																	
												</div>
												Clear answers and start again
											</li>
										</ul>
									</div>
								</div>
							)}							
							</div>
						{loading ? (
							<div className="loading">Loading…</div>
						) : (
							<>
								<ol>
									{questions.map((thisRow, i) =>
										<RenderGapFill 
											key={i}
											content={thisRow}
											questions={questions}
											setQuestions={setQuestions}
											exercise={exercise}
											setExercise={setExercise}
										/>
									)}			
								</ol>
								<Mark
									exercise={exercise}
									handleSaveWork={handleSaveWork}
									saving={saving}
								/>
						</>
						)}						
						
					</div>
				</div>
				<Keyboard />
			</>
		);
	}
	
}