import { useEffect, useState } from 'react';

import { Button } from './atoms';


/* Molecules */

export function RenderGapFill({
		content, 
		questions,
		setQuestions,
		exercise,
		setExercise
	}) {
	
	var fragmentContent = [];
	var splitArray = content.content.match(/(.+)(\([^)]+\))(\[[^)]+\])(.+)/);
	
	splitArray.map((thisFragment, i) => {
		var findPrompt = thisFragment.match(/\(([^)]+)\)/);
		var findAnswer = thisFragment.match(/\[([^)]+)\]/);
		if(findPrompt) {
			fragmentContent.push((<>(<em>{findPrompt[1]}</em>) </>));
		}	else if(findAnswer) {
			fragmentContent.push(
				<AnswerField
					i={i}
					content={content}
					questions={questions}
					setQuestions={setQuestions}
					exercise={exercise}
				/>
			);
		} else {
			fragmentContent.push(thisFragment);
		}
	});
	
	return(
		<li>
			<div className="numeral">{content.number}</div>
			{fragmentContent.map((thisFragment, i) =>
				<div key={i}>
				{(i > 0) && (
					<>{thisFragment}</>
				)}
				</div>
			)}
		</li>
	);
	
}

export function Mark({ 
		exercise,
		handleSaveWork,
		saving
	}) {
	
	const [status, setStatus] = useState("");
	const [message, setMessage] = useState(undefined);
	
	useEffect(() => {
		if(exercise.rightAnswers === exercise.questionCount) {
			setMessage("💪 Vel gert!");
			setStatus("complete");
		} else {
			setMessage(undefined);
			setStatus("incomplete");
		}
	}, [exercise.rightAnswers]);
	
	return(
		<div className={`mark ${status}`}>
			{status === "incomplete" && (
				<Button
					disabled={saving}
					label={saving ? "Saving…" : "Save work and finish later"}
					onClick={handleSaveWork}
					role="secondary"
				/>
			)}
			<span>{message}</span>
			<div className="mark-wrapper">
				{exercise.rightAnswers}
			</div>
		</div>
	)
}

export function Keyboard() {
	
	function handleCloseKeyboard() {
		document.getElementById('keyboard').classList.remove('visible');
	}
	
	function handleInsertCharacter(char) {
		var lastFocus = localStorage.getItem("lastFocus");
		lastFocus = document.getElementById(lastFocus);
		var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
		nativeInputValueSetter.call(lastFocus, lastFocus.value+char);		
		var inputEvent = new Event('input', { bubbles: true});
		lastFocus.dispatchEvent(inputEvent);
		lastFocus.focus();
	}
	
	return(
		<div id="keyboard">
			<Button label="á" onClick={() => handleInsertCharacter('á')} />
			<Button label="ð" onClick={() => handleInsertCharacter('ð')} />
			<Button label="é" onClick={() => handleInsertCharacter('é')} />
			<Button label="í" onClick={() => handleInsertCharacter('í')} />
			<Button label="ó" onClick={() => handleInsertCharacter('ó')} />
			<Button label="ú" onClick={() => handleInsertCharacter('ú')} />
			<Button label="ý" onClick={() => handleInsertCharacter('ý')} />
			<Button label="þ" onClick={() => handleInsertCharacter('þ')} />
			<Button label="æ" onClick={() => handleInsertCharacter('æ')} />
			<Button label="ö" onClick={() => handleInsertCharacter('ö')} />
			<button className="specialchars close" onClick={handleCloseKeyboard}>
				<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12.3594 10.6406C12.8672 11.1094 12.8672 11.9297 12.3594 12.3984C12.125 12.6328 11.8125 12.75 11.5 12.75C11.1484 12.75 10.8359 12.6328 10.6016 12.3984L6.5 8.29688L2.35938 12.3984C2.125 12.6328 1.8125 12.75 1.5 12.75C1.14844 12.75 0.835938 12.6328 0.601562 12.3984C0.09375 11.9297 0.09375 11.1094 0.601562 10.6406L4.70312 6.5L0.601562 2.39844C0.09375 1.92969 0.09375 1.10938 0.601562 0.640625C1.07031 0.132812 1.89062 0.132812 2.35938 0.640625L6.5 4.74219L10.6016 0.640625C11.0703 0.132812 11.8906 0.132812 12.3594 0.640625C12.8672 1.10938 12.8672 1.92969 12.3594 2.39844L8.25781 6.53906L12.3594 10.6406Z" fill="#1C1E21"/>
				</svg>				
			</button>
		</div>
	)
}


/* Atoms */

function AnswerField({
		content,
		i,
		questions,
		setQuestions,
		exercise
	}) {
	
	const [answerFieldState, setAnswerFieldState] = useState(content.status);
	
	useEffect(() => {
		setQuestions(questions.map(thisRow => {
			if(content.number === thisRow.number) {
				return { 
					...thisRow,
					status: answerFieldState
				};
			} else {
				return thisRow;
			}
		}));
	}, [answerFieldState]);
	
	const handleUpdateUserAnswer = (e) => {
		setQuestions(questions.map(thisRow => {
			if(content.number === thisRow.number) {
				return { 
					...thisRow,
					userAnswer: e.target.value
				};
			} else {
				return thisRow;
			}
		}));
	}	
	
	const handleVerifyAnswer = (e) => {
		localStorage.setItem("lastFocus", e.target.name);
		if(e.target.value) {	
			if(e.target.value.trim() === content.answer) {
				setAnswerFieldState("correct");
				e.target.blur();
			} else {
				setAnswerFieldState("error");
			}					
		} else {
			setAnswerFieldState("unfilled");
		}	
	}
	
	const handleEnterKey = (e) => {
		if(e.keyCode === 13) {
			handleVerifyAnswer(e);
			if((content.number) < exercise.questionCount) {
				var nextField = `answer-${content.number+1}`;
				document.getElementById(nextField).focus();
			}			
		}
	}
	
	return(
		<div className={`answer ${answerFieldState}`}>
			<input 
				key={i}
				id={`answer-${content.number}`}
				name={`answer-${content.number}`}
				type="text" 
				autoComplete="off"
				spellCheck="false"
				onChange={e => handleUpdateUserAnswer(e)}
				onBlur={e => handleVerifyAnswer(e)}
				onKeyUp={e => handleEnterKey(e)}
				value={content.userAnswer}
			/>
			{(answerFieldState === "correct" && (
				<div className="icon color">
					<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="12" cy="12.5" r="12" fill="#1CA2B2"/>
					<path d="M7 13.5L11 17.5L17 7.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</div>
			))}
			{(answerFieldState === "error" && (
				<div className="icon">
					<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="12" cy="12.5" r="12" fill="#EC1801"/>
					<circle cx="12" cy="18.5" r="2" fill="white"/>
					<path d="M12 5.5L12 13.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
					</svg>
				</div>
			))}
		</div>
	);
}