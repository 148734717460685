import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { stringify, parseObject } from 'qs';

import { getToken } from '../helpers';

import { BreadcrumbsContext } from '../contexts';
import ProgressBar from '../components/progressBar';
import { Button, ReloadButton } from '../components/atoms';
import { BlockRenderer } from '../components/renderers';

import Conversation from '../lessonContentTypes/conversation';
import Verb from '../lessonContentTypes/verb';
import Exercise from '../lessonContentTypes/exercise';

function NavigationButton({
	disabled,
	icon,
	iconPlacement,
	label,
	onClick,
	role,
	to
}) {
	return(
		<>
			{disabled ? (				
				<Button 
					disabled={disabled}
					icon={icon}
					iconPlacement={iconPlacement}
					label={label}
					role={role}
				/>
			) : (			
				<Link 
					to={to}
					onClick={onClick}
				>	
				<Button 
					icon={icon}
					iconPlacement={iconPlacement}
					label={label}
					role={role}
				/>
				</Link>
			)}		
		</>
	)
}

function SkeletonLoader() {
	return(
		<>
			{document.body.classList.contains('conversation') ? (				
				<>
					<div className="block skeleton"></div>
					<div className="nav-buttons">
						<div className="left">
							<div className="button skeleton"></div>
						</div>
						<div className="right">
							<div className="button skeleton"></div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="prose">
						<h1 className="skeleton">Lesson page 1</h1>
					</div>
					<p className="skeleton">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam semper ante sit amet fermentum facilisis.</p>
					<div className="block skeleton"></div>
					<div className="nav-buttons">
						<div className="left">
							<div className="button skeleton"></div>
						</div>
						<div className="right">
							<div className="button skeleton"></div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

function parsePageList(data) {
	var pageObject = {
		pageContext: null,
		currentPage: null,
		lastPage: null,
		nextPage: null
	}
	var pageList = data.attributes.lesson.data.attributes.lessonPages.data
	var currentPageIndex = pageList.findIndex(({ id }) => id === data.id);
	pageObject.currentPage = pageList.slice(currentPageIndex, currentPageIndex+1);
	pageObject.lastPage = pageList.slice(currentPageIndex-1, currentPageIndex);
	pageObject.nextPage = pageList.slice(currentPageIndex+1, currentPageIndex+2);
	var pageContext = {
		pageCount: pageList.length,
		currentPagePos: currentPageIndex+1,
		progressBarwidth: ((currentPageIndex+1) / pageList.length)*100+"%",
	};
	pageObject.pageContext = pageContext;
	return(pageObject);
}

export default function LessonPage() {
		
	const authToken = getToken();
	
	const { t, i18n } = useTranslation();
	
	const breadcrumbs = useContext(BreadcrumbsContext);
	const courseSlug = useParams().courseSlug;
	const lessonSlug = useParams().lessonSlug;
	const pageSlug = useParams().pageSlug;

	const [pageData, setPageData] = useState(null);
	const [pageList, setPageList] = useState({
		pageContext: {
			progressBarWidth: 0
		}});
	
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	
	const [nextPageDisabled, setNextPageDisabled] = useState(false);
	
	function handlePageNavigate(type) {
		if(type === 'conversation') {
			document.body.classList.add('conversation');
		} else {
			document.body.classList.remove('conversation');
		}
		setLoading(true);
		setNextPageDisabled(false);
	}
	
	useEffect(() => {			
		if(pageData) {
			document.body.classList.toggle('conversation', pageData.attributes.type === 'conversation');
		}
	}, [pageData]);
	
	var q = stringify({
		sort: ['slug:asc'],
		filters: {
			slug: {
				$eq: pageSlug,
			},
		},
		populate: {
			content: {
				populate: {
					image: {
						populate: '*',
					},
					audio: {
						populate: ['*'],
					},
					row: {
						populate: ['audio', 'image'],
						orderBy: [{ column: 'asc' }]
					},
				},
			},
			exercise: {
				populate: {
					content: {
						populate: ['rows'],
					},
				},
			},
			lesson: {
				populate: ['lessonPages', 'course'],
			},
		},
		publicationState: 'live',
	});
	
	const fetchData = (retries) => {
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/lesson-pages?${q}`, {
			headers: {
				'Authorization': `Bearer ${authToken}`
			}
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {			
			if(data.data.length>0) {
				setPageData(data.data[0]);
				setPageList(parsePageList(data.data[0]));
				setLoading(false);
				var newBreadcrumbs = [
					{
						path: '/dashboard',
						title: 'Icelandic with Max',
						mobileStyle: 'compact'
					}, 
					{	path: '/'+data.data[0].attributes.lesson.data.attributes.course.data.attributes.slug+'/', 
						title: data.data[0].attributes.lesson.data.attributes.course.data.attributes.name,
						mobileStyle: 'hidden'	
					},
					{	path: '/'+data.data[0].attributes.lesson.data.attributes.course.data.attributes.slug+'/'+data.data[0].attributes.lesson.data.attributes.slug+'/', 
						title: data.data[0].attributes.lesson.data.attributes.title,
						dropdownItems: data.data[0].attributes.lesson.data.attributes.lessonPages.data,
						dropdownOnClick: () => setLoading(true)
					}
				];
				breadcrumbs.setBreadcrumbs(newBreadcrumbs);
			} else {
				setPageData(null);	
				setError(true);				
				setLoading(false);
			}
		})
		.catch((error) => {
			setPageData(null);	
			setError(true);		
			setLoading(false);
			console.log(error);
			if(retries > 0) {
				console.log(`Retrying request, ${retries} attempts left…`);
				return fetchData(retries - 1);
			}
		});
	}
	
	useEffect(() => {		
		var retries = 3;
		fetchData(retries);
	}, [pageSlug]);
	
	function PageNavButtons() {
		return(
			<div className="nav-buttons">
				<div className="left">
				{pageList.lastPage.length > 0 ? (			
					<NavigationButton
						icon="previous"
						iconPlacement="before"
						label={pageList.lastPage[0].attributes.longTitle ? (
							pageList.lastPage[0].attributes.longTitle
						) : (
							pageList.lastPage[0].attributes.title
						)} 
						onClick={() => handlePageNavigate(pageList.lastPage[0].attributes.type)}
						role="secondary"
						to={`/${courseSlug}/${lessonSlug}/${pageList.lastPage[0].attributes.slug}/`}		
					/>
				) : (
					<NavigationButton
						icon="previous"
						iconPlacement="before"
						label={t('lessonPage.buttonBackToOverview')}
						onClick={handlePageNavigate}
						role="secondary"
						to={`/${courseSlug}/${lessonSlug}/`}
					/>
				)}
				</div>
				<div className="right">
				{pageList.nextPage.length > 0 ? (		
					<NavigationButton
						disabled={nextPageDisabled}
						label={pageList.nextPage[0].attributes.longTitle ? (
							pageList.nextPage[0].attributes.longTitle
						) : (
							pageList.nextPage[0].attributes.title
						)}
						icon="next"
						iconPlacement="after"
						onClick={() => handlePageNavigate(pageList.nextPage[0].attributes.type)} 
						to={`/${courseSlug}/${lessonSlug}/${pageList.nextPage[0].attributes.slug}/`}
					/>
				) : (
					<NavigationButton
						disabled={nextPageDisabled}
						label={t('lessonPage.buttonFinishLesson')}
						icon="next"
						iconPlacement="after"
						onClick={handlePageNavigate}
						to={`/${courseSlug}/`}
					/>
				)}							
				</div>
			</div>
		)
	}
	
	return(
		<>
			<ProgressBar width={pageList.pageContext.progressBarwidth} lesson={true} />
			<div className="container lesson">
				{loading ? (
					<SkeletonLoader />
				) : (
					<>
					{error ? (
						<div className="error-wrapper">
							<p>This page could not be loaded</p>
							<ReloadButton title="Try again" onClick={() => window.location.reload()} />
						</div>
					) : (
						<>
							{(pageData && pageList) && (
								<>								
									{pageData.attributes.type === 'conversation' && (
										<Conversation content={pageData.attributes} />
									)}
									{pageData.attributes.type === 'verb' && (
										<Verb content={pageData.attributes} />
									)}
									{pageData.attributes.type === 'exercise' && (
										<Exercise 
											content={pageData.attributes} 
											nextPageDisabled={nextPageDisabled}
											setNextPageDisabled={setNextPageDisabled}
										/>
									)}
									{((pageData.attributes.type === 'vocab') || (pageData.attributes.type === 'pronunciation') || (pageData.attributes.type === 'summary')) && (
										<>				
											<div className="prose">
												<h1>{pageData.attributes.title}</h1>
											</div>
											<BlockRenderer content={pageData.attributes.content} />
										</>
									)}
									{pageData.attributes.type === 'summary' && (
										<ul className="course-contents">
											{pageData.attributes.lesson.data.attributes.lessonPages.data.map((lesson, i) =>
												<Link to={`/${courseSlug}/${lessonSlug}/${lesson.attributes.slug}/`} key={i}>
													<li>
														{lesson.attributes.longTitle ? (
															lesson.attributes.longTitle
														) : (
															lesson.attributes.title
														)}
													</li>
												</Link>			
											)}
										</ul>
									)}
									<PageNavButtons />
								</>	
							)}	
						</>
					)}	
					</>				
				)}
			</div>
		</>
	)
}